export default defineNuxtRouteMiddleware (async () => {
  if (import.meta.server) return

  try {
    const { result } = await useNuxtApp().$trpc.personal.checkOrderAccess.mutate()
  
    if (!result) {
      return navigateTo(`/otp`)
    }
  } catch (err) {
    return navigateTo('/')
  }
})
